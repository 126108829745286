import * as React from 'react'
import { graphql , Link} from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx' // highlight-line
import Layout from '../../components/layout'
import SEO from '../../components/seo'





const BlogPost = ({ data }) => { 

  return (  
    <Layout pageTitle={data.mdx.frontmatter.title}>
        <SEO title={data.mdx.frontmatter.title} description={data.mdx.frontmatter.description}  />
      
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><Link to='/blog'>Blogs</Link></li>
        <li class="breadcrumb-item active" aria-current="page">{data.mdx.frontmatter.title}</li>
      </ol>
    </nav>
      <p>{data.mdx.frontmatter.date}</p>
      <h1>{data.mdx.frontmatter.title}</h1>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>

      <p>&nbsp;</p>

    
      <p>&nbsp;</p>

   
  
     



 
    </Layout>
    
  )
}

export const query = graphql`
query MyQuery($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      title
      date(formatString: "MMMM D, YYYY")
      description
      link
    }
    body
    slug
  }

 
}
`



export default BlogPost